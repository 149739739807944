/* Main Container */
  .contact-list-container {
    padding: 20px;
    max-width: 1200px; /* You can adjust this as needed */
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #f7f8fc;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: relative; /* Ensure it is positioned relative for absolute positioning of child elements */
    display: flex; /* Use flexbox to allow children to adjust dynamically */
    flex-direction: column; /* Stack children vertically */
    align-items: stretch; /* Let children take full width */
    min-height: 400px; /* Set a minimum height if needed */
}
  
  /* Wrapper for the Contact List */
  .contact-list-wrapper {
    margin-top: 20px;
    position: relative;
  }
  
  /* Header Styling */
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
  
  .profile {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  .user-name {
    margin-right: 10px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: box-shadow 0.3s ease;
  }
  
  .profile-pic:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  
  /* Header Actions (Filter and Add Buttons) */
  .header-actions {
    display: flex;
    /* justify-content: flex-end; */
    justify-content: flex-start; /* Align items to the start */
    align-items: center;
    margin-bottom: 5px;
    position: relative;
  }
  
  /* Filter and Add Contact Buttons */
  .add-contact-btn,
  .filter-btn {
    padding: 10px 15px;
    margin-left: 10px;
    border: none; /* Removed border for both buttons */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    align-items: center;
    font-weight: bold;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    justify-content: center;
  }
  
  .add-contact-btn {
    background-color: #6a1b9a;
    color: white;
  }
  
  .filter-btn {
    background-color: white;
    color: #6a1b9a;
  }

  /* Filter Button */
  .filter-btn {
    position: relative; /* Relative positioning to align the modal */
    background-color: white; /* Default to white */
    border: none;
    border-radius: 8px; /* Rounded corners for consistency */
    padding: 8px 12px;
    margin-right: 10px; /* Right margin to provide space for the modal */
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  
  .filter-btn.active {
    background-color: #D4C2FC; /* Active background color */
  }
  
  /* Filter Modal */
  .filter-modal {
    position: absolute; /* Absolute positioning to place it at a specific location */
    top: 87px; /* Given position for the modal */
    right: 250px; /* Given position for the modal */
    background-color: #D4C2FC;
    border-radius: 8px; /* Same rounded corners as the button */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    padding: 3px; /* Padding as specified */
    z-index: 10; /* Ensure it appears above other elements */
  }
  
  /* Filter Input */
  .filter-input {
    width: 100px; /* Set input width to 100px */
    padding: 6px; /* Adjust padding for better alignment */
    border: 1px solid #ccc;
    border-radius: 8px; /* Same rounded corners */
    outline: none;
  }
  
  /* Animation for the filter modal sliding in */
  @keyframes slideInFromRight {
    from {
      transform: translateX(20%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  
  .filter-btn:hover {
    background-color: #f3e5f5;
    color: #6a1b9a;
  }
  
  .add-contact-btn:hover {
    background-color: #8e24aa;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .add-contact-btn.active {
    background-color: #D4C2FC; /* Updated to blend with modal */
    color: white;
  }
  
  /* Modal for Adding Contacts */
  .modal {
    position: absolute; 
    top: 124px; 
    right: 0px; /* Align to the right */
    background-color: #D4C2FC; 
    padding: 20px 30px; 
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    z-index: 1000; 
    display: none;
  }
  
  /* Show Modal when the class is applied */
  .animated-modal {
    display: block; 
    animation: slideIn 0.4s ease-out; 
  }
  
  /* Animation for sliding in */
  @keyframes slideIn {
    from {
      transform: translateY(-10px); 
      opacity: 0;
    }
    to {
      transform: translateY(0); 
      opacity: 1;
    }
  }
  
  /* Modal Inputs, Save, and Cancel Buttons */
  .modal-input {
    width: calc(100% - 30px);
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f7f7f7;
  }
  
  .save-btn,
  .cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
  }
  
  .save-btn {
    background-color: #4caf50;
    color: white;
  }
  
  .save-btn:hover {
    background-color: #45a049;
  }
  
  .cancel-btn {
    background-color: #f44336;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #e53935;
  }
  
  /* Contact Table Styling */
  .contact-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .contact-table-header {
    display: grid;
    grid-template-columns: 2fr 2fr 1.5fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr;
    gap: 10px;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid #ddd;
    padding: 10px 0;
    align-items: center;
  }
  
  .contact-column {
    text-align: left; 
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 5px; 
  }
  
  .contact-column.phone-column,
  .contact-column.email-column,
  .contact-column.linkedin-column {
    text-align: center;
    justify-content: center; 
  }
  
  /* Adjust column widths for Name and Job Title */
  .name-column,
  .job-title-column {
    text-align: left; 
  }
  
  /* Contact Row Styling */
  .contact-table-row {
    display: grid;
    grid-template-columns: 2fr 2fr 1.5fr 1fr 1fr 1fr 2fr 1fr 1fr 1fr; 
    gap: 10px;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
    align-items: center;
  }
  
  .contact-name,
  .contact-jobTitle,
  .contact-company,
  .contact-todo {
    font-size: 14px;
    color: #555;
    padding: 5px;
    text-align: left;
    vertical-align: middle;
  }
  
  .contact-phone,
  .contact-email,
  .contact-linkedin {
    text-align: center;
    vertical-align: middle;
  }
  
  .contact-linkedin a {
    display: block;
  }
  
  .inline-edit-input {
    border: none;
    background-color: #f7f7f7;
    border-bottom: 2px solid #ccc;
    padding: 4px;
    font-size: 14px;
    width: 100%;
    outline: none;
  }
  
  .inline-edit-input:focus {
    border-bottom-color: #6a1b9a;
  }
  
  /* Floating Button (Edit/Delete Actions) */
  .floating-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    margin: 5px;
    transition: all 0.3s ease;
  }
  
  .floating-btn:active {
    transform: scale(0.95);
  }
  
/* Delete Button (Default State) */
.delete-btn {
  background-color: white;
  color: #f44336;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto; /* Adjust width for rectangular shape */
  padding: 10px 20px; /* Padding for rectangular shape */
  border-radius: 8px; /* Rounded corners for rectangular shape */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 5px;
  transition: all 0.3s ease;
}

.delete-btn:hover {
  background-color: #ffebeb;
  color: #f44336;
}

/* Delete Button (Confirmation State) */
.delete-confirm {
  background-color: #f44336; /* Confirm button background color */
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px; /* Padding for rectangular shape */
  border-radius: 8px; /* Rounded corners for rectangular shape */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.delete-confirm:hover {
  background-color: #e53935; /* Slightly darker red on hover */
}

  
  /* Todo Tags */
  .todo-tag {
    display: inline-flex; 
    align-items: center;
    background-color: #e0e0e0;
    padding: 4px 6px; 
    margin: 2px;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    vertical-align: middle;
  }
  
  .todo-completed {
    background-color: #4caf50;
    color: #fff;
    display: inline-flex; 
    align-items: center;
    padding: 4px 6px;
  }
  
  .todo-completed .fa-check {
    margin-right: 5px; 
    font-size: 12px; 
  }
  
  /* Created/Updated Info */
  .user-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
  }
  
  .created-info,
  .updated-info {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
  }
  
  .created-info div,
  .updated-info div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  /* Stats at the bottom of the list */
  .contact-stats {
    flex: 1; /* Allow contact-stats to take available space */
    margin-right: 0; /* Remove the right margin */
    padding: 15px; /* Keep padding for spacing within */
    background-color: #fff; /* Background color for contact stats */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    font-weight: bold; /* Make the font bold */
    display: flex; /* Use flexbox for layout */
    flex-direction: row; /* Layout items in a row */
    gap: 20px; /* Gap between items */
    align-items: center; /* Align items to the center */
    justify-content: flex-start; /* Align items to the start */
    color: #888; /* Text color */
}


  
  /* Style for individual items inside the contact-stats */
  .contact-stats-item {
    display: flex;
    align-items: center;
    gap: 5px; 
  }
  
  /* File drop area for importing contacts */
.file-drop-area {
    width: 50%;  /* Set width to 50% */
    padding: 20px;
    border: 2px dashed #6a1b9a;
    border-radius: 8px;
    text-align: center;
    margin: 10px auto;  /* Center the drop area */
    background-color: #f7f8fc;
    cursor: pointer;
  }
  
  .file-drop-area:hover {
    background-color: #e3e3f7;
  }
  

  .stats-and-messages-container {
    display: flex; /* Use Flexbox for horizontal alignment */
    flex-direction: row; /* Align items horizontally */
    align-items: flex-start; /* Align items to the top */
    width: 100%; /* Full width of the container */
    gap: 20px; /* Space between contact-stats and message-board */
    margin-top: 10px; /* Remove any margins */
    padding: 0; /* Remove any padding */
}




  
.contact-stats {
  display: flex; /* Use Flexbox for horizontal layout */
  flex-wrap: wrap; /* Allow items to wrap to the next line if there isn't enough space */
  gap: 10px; /* Space between items */
  padding: 15px; /* Padding for spacing within */
  background-color: #fff; /* Background color for contact stats */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  font-weight: bold; /* Make the font bold */
  align-items: flex-start; /* Align items to the top */
  justify-content: flex-start; /* Align items to the start */
  color: #888; /* Text color */
  margin: 0; /* Remove all margins */
  width: 100%; /* Ensure full width */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
}





.message-board {
  height: 150px; /* Fixed height to make content scrollable */
  overflow: hidden; /* Hide overflow to avoid showing scrollbars */
  background-color: #f9f9f9; /* Background color for readability */
  border: 1px solid #ddd; /* Border around the message board */
  position: relative; /* Position relative to contain child elements */
  padding: 10px; /* Keep padding for better readability */
  margin-left: 20px; /* Adjusted margin if needed */
  cursor: pointer; /* Cursor style */
}


.message-board-content {
  position: relative; /* Change from absolute to relative to fit within container */
  white-space: normal; /* Allow normal wrapping of text */
  display: block; /* Block display to allow proper scrolling */
  padding: 0; /* Remove any additional padding */
}

/* Keyframes for scrolling effect */
@keyframes scrollText {
  0% { transform: translateY(100%); }
  100% { transform: translateY(-100%); } /* Scroll from bottom to top */
}

.message-board p {
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove padding */
  line-height: 1.5; /* Adjust line height */
}

.scroll-arrow {
  font-size: 18px; /* Adjust arrow size */
  color: #888; /* Arrow color */
  background: #fff; /* Background color to make it stand out */
  padding: 2px 5px; /* Padding around the arrow */
  border-radius: 3px; /* Rounded corners */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Add slight shadow for depth */
  cursor: pointer; /* Make it look clickable */
  text-align: center; /* Center text alignment */
}






